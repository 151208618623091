import { createApp } from 'vue/dist/vue.esm-bundler';
import App from './App.vue'
import { createPinia } from 'pinia'
import { i18n, changeLanguage } from './i18n';
//import { useSessionStore } from '@/stores/session'

const app = createApp(App);
const pinia = createPinia();

app.config.globalProperties.$changeLanguage = changeLanguage;
app.config.globalProperties.filters = {
    formatMoney(val){
        return new Intl.NumberFormat("en-GB").format(val);
    }
}

app.use(pinia).use(i18n).mount('#v9app')
//app.config.globalProperties.$session = useSessionStore();
const perferLan = ['vi','th','en'].indexOf(navigator.language.substring(0,2)) !==-1 ? navigator.language.substring(0,2) : 'vi';
changeLanguage(perferLan)
