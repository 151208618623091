import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useSessionStore = defineStore('sessionStore', () => {
  const language = ref('vi');
  function changeLanguage(val){
    language.value = val;
  }
  return { language,changeLanguage }
});

