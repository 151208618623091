import { createI18n  } from 'vue-i18n' 

function loadLocaleMessages() {
    const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
    const messages = {};
    locales.keys().forEach(key => {
      const matched = key.match(/([A-Za-z0-9-_]+)\./i);
      if (matched && matched.length > 1) {
        const locale = matched[1];
        messages[locale] = locales(key);
      }
    });
    return messages;
}

const i18n = createI18n({
  legacy: false,
  locale: 'vi', // 預設語言
  fallbackLocale: 'en',
  messages: loadLocaleMessages() // 載入語言文件
});

function changeLanguage(lang) {
    i18n.global.locale.value = lang;
    document.body.className = lang;
}

function generateTimestamp() {
  const now = new Date();
  const minutes = 5;
  const msInMinute = 60000;

  const elapsed = now.getMinutes() % minutes * msInMinute + now.getSeconds() * 1000 + now.getMilliseconds();

  return new Date(now - elapsed).getTime();
}

export { i18n, changeLanguage, generateTimestamp };