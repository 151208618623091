<template>
  <v-runtime-template :template="processedHtml" :components="dynamicComponents"></v-runtime-template>
</template>
  
<script>
import axios from 'axios'
import VRuntimeTemplate from "vue3-runtime-template"
import TernNConditions from './TernNConditions'

const generateTimestamp = function() {
    const now = new Date();
    const minutes = 5;
    const msInMinute = 60000;

    const elapsed = now.getMinutes() % minutes * msInMinute + now.getSeconds() * 1000 + now.getMilliseconds();

    return new Date(now - elapsed).getTime();
}

export default {
  name: 'LoadTemplate',
  components: {
    VRuntimeTemplate,
    // eslint-disable-next-line
    TernNConditions
  },
  data() {
    return {
      processedHtml : '',
      leaders : [],
      dynamicComponents:{
        TernNConditions
      }
    };
  },
  methods: {
    changeLanguage(lang){
      this.$changeLanguage(lang);
    },
  },
  mounted() {
    axios.all([
      axios.get('https://ibr-doc-cdn.docbv9a1.com/csh/v9bet/CDN/leaderboard/template.html?v=' + generateTimestamp()),
      axios.get('https://ibr-doc-cdn.docbv9a1.com/csh/v9bet/CDN/leaderboard/winners.json?v=' +generateTimestamp())
    ])
    .then(axios.spread((htmlResponse, jsonResponse) => {
      this.processedHtml = htmlResponse.data;
      this.leaders = jsonResponse.data.sort((a,b) =>{ return b.result-a.result;});
    }))
    .catch(error => {
      console.error('Error fetching external resources:', error);
    });
  }
};
</script>
  