<template>
    <v-runtime-template :template="rawhtml"></v-runtime-template>
</template>
  
<script>
import axios from 'axios'
import { useSessionStore } from '@/stores/session'
import VRuntimeTemplate from "vue3-runtime-template"

function generateTimestamp() {
    const now = new Date();
    const minutes = 5;
    const msInMinute = 60000;

    const elapsed = now.getMinutes() % minutes * msInMinute + now.getSeconds() * 1000 + now.getMilliseconds();

    return new Date(now - elapsed).getTime();
}

export default {
  name: 'TernNConditions',
  components: {
    VRuntimeTemplate
  },
  data() {
    const session = useSessionStore();
    return {
      toggle: false,
      rawhtml: '',
      language: session.language
    };
  },
  methods: {
    switchlan(lan){
      this.language = lan;
      this.loadContent();
    },
    toggleContent(){
      this.toggle = !this.toggle;
    },
    loadContent(lan) {
        const perlan = lan || this.language;
        const url = `https://ibr-doc-cdn.docbv9a1.com/csh/v9bet/CDN/leaderboard/tnc_${perlan}.html?v=${generateTimestamp()}`;
        axios.get(url)
          .then((response) => {
          this.rawhtml = response.data;
          })
          .catch((error) => {
            console.error('Error fetching external resources:', error);
          });
    }
  },
  mounted() { 
    const perferLan = ['vi','th','en'].indexOf(navigator.language.substring(0,2)) !==-1 ? navigator.language.substring(0,2) : 'vi';
    this.loadContent(perferLan);
  },
  watch: {
  '$i18n.locale': function(newVal) {
    this.switchlan(newVal);
   }
  }
};
</script>
<style>
.smooth-enter-active, .smooth-leave-active {
  transition: max-height.3s;
}
.smooth-enter, .smooth-leave-to {
  max-height: 0px;
}
</style>